<template>
  <div>
		<h1 class="text-4xl text-center py-5" v-if="contactUs">{{ contactUs.title }}</h1>
		<container max-w="6xl mx-10">
			<div v-if="contactUs" v-html="contactUs.content"></div>
		</container>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Container from "../../layouts/container";

export default {
  name: "Contact",
  components: {
	  Container,
	},
	data: {

	},
	computed: {
		...mapGetters({
            contactUs: 'pages/contactUs',
        }),
	},
	created() {
      this.$store.dispatch('pages/ContactUsPage');
  },
};
</script>

<style scoped>
</style>
